import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Volcanoes from '../../Images/Volcanoes.jpg';
import Nohur from '../../Images/Nohur.jpg';
import conatctcall from '../../Images/contact-call.png';
import Baku from '../../Images/Baku.jpg';
import dubai1 from '../../Images/dubai1.webp';
import Ranthambhore from '../../Images/Ranthambhore.jpg';
import Tirupati from '../../Images/Tirupati.jpg';
import Boulevard from '../../Images/Boulevard.jpg';


export const BakuDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToTirupathi = () => {
    navigate('/Thirupathi-details'); 
  };
  const handleNavigateToDubai = () => {
    navigate('/dubai-details'); 
  };
  const handleNavigateToRantham = () => {
    navigate('/ranthambhore-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Baku} alt="Baku" />
        <div className='details'>
            <h1>BAKU & GABALA</h1>
            <h2>Baku | Gabala | Mud Volcanoes & Gobustan</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our BAKU & GABALA Tour</h3>

            <div className='first-day'>
              <h4>Day 01 : Arrival Baku </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrival at 10:55, visit Heydar Aliyev Center, then check into the hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch, followed by Baku Panoramic Tour, visit to Baku Crystal Hall, Little Venice, and walk through Baku Boulevard.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at a restaurant, overnight stay in Baku hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 02 : Baku City Tour </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast and city tour of Old and New Baku, including Baku TV Tower and UNESCO-listed Old City.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visit Highland Park, Alley of Martyrs, National Assembly, and Flame Towers.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch in a restaurant, shopping on Nizami Street.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Trip to "Fire Mountain" (Yanardag), a natural gas fire that blazes continuously.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner in a restaurant.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>


            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 03: Mud Volcanoes & Gobustan Group Tour </h4>
              <div className='content-data'>
                <img src={Volcanoes} alt="Volcanoes" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>After breakfast, visit Gobustan State Historical and Cultural Museum and Mud Volcanoes.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Return to Baku for lunch at a restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Evening tour of old oil wells, metro tour, and shopping at Baku Mall.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner in a restaurant.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 04 : Baku-Gabala  </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at the hotel, check out, and store luggage in the hotel store room.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Travel to Gabala, 225 km from Baku (approx. 3 hours).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at a restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visit Nohur Lake for scenic views.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Excursion to "Tufandaq" Winter-Summer Tourism Complex for cable car rides and optional skiing.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at a restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight stay at Garden Hotel in Gabala.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 05: Gabala TO Baku </h4>
              <div className='content-data'>
                <img src={Nohur} alt="Nohur" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>After Breakfast in Hotel processed to Water fall.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch AT Picnic Area.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Stay Baku Leisure / your Owen shopping at Mall.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at a restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight stay in Gabala at Graden Hotel.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 06 : Baku City and Shopping/ Fruit Market/ Boat Ride </h4>
              <div className='content-data'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at the hotel, then visit the Baku Ferris Wheel (Baku Eye) on Baku Boulevard.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at a restaurant in Baku.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Start shopping tour: Local Sweet Shop (Aliahmad's), wine and alcohol shop, dry fruits, sweets, and antique shops.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visit Genclik Mall with over 80 stores, movie halls, bowling, food courts, and more.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Optional boat ride on the Caspian Sea or gondola ride (weather permitting).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at a restaurant.</h5></li>
                </ul>
                <img src={Boulevard} alt="Boulevard" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07 : Baku Departure   </h4>
              <div className='content-data1'>
                <h5>Breakfast in Hotel. Check out Hotel Transfer to Airport for return flight</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>


        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              <div className='date-container' onClick={handleNavigateToDubai} style={{ cursor: 'pointer' }}>
                <img src={dubai1} alt='dubai' className='most-img' />
                <p className='time'>DUBAI TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToRantham} style={{ cursor: 'pointer' }}>
                <img src={Ranthambhore} alt='Ranthambhore' className='most-img' />
                <p className='time'>RANTHAMBHORE TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToTirupathi} style={{ cursor: 'pointer' }}>
                <img src={Tirupati} alt='Tirupati' className='most-img' />
                <p className='time'>THIRUPATHI & CHENNAI SHOPPING</p>
              </div>
              
              
          </div>
        </div>
      </div>

     </div>
    </>
  )
}

