import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Tbilisi from '../../Images/Tbilisi.jpg';
import Mtskheta from '../../Images/Mtskheta.jpg';
import moscow from '../../Images/moscow.jpg';
import Batumi from '../../Images/Batumi.webp';
import Bakuriani from '../../Images/Bakuriani.jpg';
import Uplistsikhe from '../../Images/Uplistsikhe.jpg';
import departure from '../../Images/flight-departure.jpg';
import conatctcall from '../../Images/contact-call.png';
import georgia from '../../Images/georgia.jpg';
import sydeny from '../../Images/sydeny.jpg';
import china from '../../Images/china.jpg';

export const GeorigaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToMoscow = () => {
    navigate('/transsiberian-details'); 
  };
  const handleNavigateToSydeny = () => {
    navigate('/sydeny-details'); 
  };
  const handleNavigateToChina = () => {
    navigate('/china-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


     <div className='mainbar'>
        <img src={georgia} alt="georgia" />
        <div className='details'>
            <h1>GEORGIA - TBILISH & DUBAI</h1>
            <h2>Tbilisi | Batumi | Bakuriani | Uplistsikhe | Gori</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our GEORGIA - TBILISH & DUBAI Tour</h3>

            <div className='first-day'>
              <h4>Day 01 : Airport Tbilisi/ Tbilisi</h4>
              <div className='content-data'>
                <h5>Arrive Tbilisi, Meet & Greet and transfer to a hotel. Dinner at a local restaurant. overnight at a hotel in Tbilisi.</h5>
                <img src={Tbilisi} alt="Tbilisi" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 02 : Tbilisi/ Mtskheta/ Batumi</h4>
              <div className='content-data'>
                <img src={Mtskheta} alt="Mtskheta" />
                <h5>Start the tour at 8:30am with a visit to Mtskheta, Georgia's ancient capital, exploring the UNESCO World Heritage sites of Jvari Church and Svetitskhoveli Cathedral. Learn about Georgia’s early Christian history. Later, transfer to Batumi for dinner and an overnight stay.</h5>
             </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03 : Batumi</h4>
              <div className='content-data'>
                <h5>Start with a morning walking tour of Batumi, visiting key landmarks like Piazza Square, Europe Square, and the Batumi port. Then, explore the Batumi Botanical Gardens and enjoy a boat trip on the Black Sea. End with dinner at an Indian restaurant and an overnight stay in Batumi.</h5>
                <img src={Batumi} alt="Batumi" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04 : Batumi/ Bakuriani</h4>
              <div className='content-data'>
                <img src={Bakuriani} alt="Bakuriani" />
                <h5>Depart at 8:30am for Bakuriani ski resort, enjoying free time for snow and winter sports. Dinner and overnight at a hotel in Bakuriani.</h5>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 05 : Bakuriani/ Gori/ Uplistsikhe/ Tbilisi</h4>
              <div className='content-data'>            
                <h5>Start with a visit to Gori, Stalin's birthplace, and explore the ancient cave city of Uplistsikhe, a former Silk Road hub. Enjoy lunch at a winery, then head to Tbilisi for dinner, shopping, and an overnight stay at a hotel.</h5> 
                <img src={Uplistsikhe} alt="Uplistsikhe" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 06 : Tbilisi/ Airport Tbilisi</h4>
              <div className='content-data'>
                <img src={departure} alt="departure" />
                <h5>Start with a tour of Old Tbilisi, visiting churches, Sulphur Baths, Narikala Fortress, and the Bridge of Peace. Explore Rustaveli Avenue before transferring to the airport. Arrive in Dubai, with dinner on your own and an overnight stay at a hotel.</h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07 : Dubai</h4>
              <div className='content-data1'>            
                <h5>Breakfast at Hotel, Free Shopping Leisure Day Lunch and Dinnser by your own</h5> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 08 : Dubai Back to Colombo</h4>
              <div className='content-data1'>
                <h5>Breakfast at Hotel, Leisure and Transfer to Airport back to Colombo Lunch by Your own</h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
            <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
              <div className='date-container' onClick={handleNavigateToSydeny} style={{ cursor: 'pointer' }}>
                <img src={sydeny} alt='sydeny' className='most-img' />
                <p className='time'>SYDENY & TASMANIAN & MALBOURE</p>
              </div>
              <div className='date-container' onClick={handleNavigateToChina} style={{ cursor: 'pointer' }}>
                <img src={china} alt='china' className='most-img' />
                <p className='time'>CHINA TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToMoscow} style={{ cursor: 'pointer' }}>
                <img src={moscow} alt='moscow' className='most-img' />
                <p className='time'>TRANSSIBERIAN ADVENTURE TOUR</p>
              </div>
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

