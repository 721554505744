import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import borobudur from '../../Images/borobudur.jpg';
import Jakarta from '../../Images/Jakarta.jpg';
import conatctcall from '../../Images/contact-call.png';
import tanah from '../../Images/tanah.webp';
import melasti from '../../Images/melasti.webp';
import Baku from '../../Images/Baku.jpg';
import dubai1 from '../../Images/dubai1.webp';
import Ranthambhore from '../../Images/Ranthambhore.jpg';


export const JakartaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToBaku = () => {
    navigate('/baku-gabala-details'); 
  };
  const handleNavigateToDubai = () => {
    navigate('/dubai-details'); 
  };
  const handleNavigateToRantham = () => {
    navigate('/ranthambhore-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Jakarta} alt="Jakarta" />
        <div className='details'>
            <h1>JAKARTA - YOGYAKARTA - BALI</h1>
            <h2>Jakarta | Miniatur Park | Prambanan | Uluwatu</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our JAKARTA - YOGYAKARTA - BALI Tour</h3>

            <div className='first-day'>
              <h4>Day 01 : Arrival - Jakarta City Tour </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrive at Ngurah Rai Airport Bali, meet & Greet with Guide, Welcome Garland.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Monument National.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Old City.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check In hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 02 : Miniatur Park - Ancol </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Indonesia Miniature Park (Main gate ticket only).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Ancol Recreation Park (Main gate ticket only).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Shoppnig at Grand Indonesia Mall or similar.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Back to hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>


            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 03 : Jakarta - Yogyakarta - Borobudur </h4>
              <div className='content-data'>
                <img src={borobudur} alt="borobudur" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Flight to Yogyakarta, meet & Greet with Guide.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Borobudur Temple (Biggest Buddhist Temple - One of seven wonder).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in Hotel.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 04 : Prambanan - Yogyakarta City Tour  </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Keraton Jogja (Sultan Palace).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Taman Sari Water Castle.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Prambanan Temple (Highest Hindu temple at south east Asia - UNESCO world Heritage).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Malioboro Street.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Back to hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 05 : Yogyakarta - Bali - Tanah Lot Tour  </h4>
              <div className='content-data'>
                <img src={tanah} alt="tanah" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Flight to Bali, meet & Greet with Guide.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Tanah Lot Temple.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in Hotel.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 06 : Fullday Watersport - Uluwatu Tour   </h4>
              <div className='content-data'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Tanjung Benoa Watersport such as Banana Boat, Parasailing, Jet Ski, etc (Personal Expense).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Melasti Beach.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Uluwatu Temple.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at Local Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Back to hotel.</h5></li>
                </ul>
                <img src={melasti} alt="melasti" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07 : Departure   </h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check out.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to airport, End of Service.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>


        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            <div className='date-container' onClick={handleNavigateToBaku} style={{ cursor: 'pointer' }}>
                <img src={Baku} alt='moscow' className='most-img' />
                <p className='time'>BAKU & GABALA</p>
              </div>
              <div className='date-container' onClick={handleNavigateToDubai} style={{ cursor: 'pointer' }}>
                <img src={dubai1} alt='dubai' className='most-img' />
                <p className='time'>DUBAI TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToRantham} style={{ cursor: 'pointer' }}>
                <img src={Ranthambhore} alt='Ranthambhore' className='most-img' />
                <p className='time'>RANTHAMBHORE TOUR</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

