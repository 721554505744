import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import FUJI from '../../Images/FUJI-SAN.webp';
import sembuwatta from '../../Images/sembuwatta.jpg';
import festival from '../../Images/Sakura-festival.webp';
import blloom from '../../Images/Sakurablloom.jpg';
import lamb from '../../Images/Sakura-lamb.jpg';
import conatctcall from '../../Images/contact-call.png';
import japan from '../../Images/japan.png';
import kenya from '../../Images/kenya.jpg';
import georgia from '../../Images/georgia.jpg';
import sydeny from '../../Images/sydeny.jpg';

export const JapanDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToSydeny = () => {
    navigate('/sydeny-details'); 
  };
  const handleNavigateToKeniya = () => {
    navigate('/kenya-details'); 
  };
  const handleNavigateToGeorgia = () => {
    navigate('/georiga-details'); 
  };



  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={japan} alt="japan" />
        <div className='details'>
            <h1>SAKURA Festival in Japan</h1>
            <h2>Sakura | FUJI SAN</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>Discover Japan’s Iconic Treasures with Sagami Travels</h3>

            <div className='first-day'>
              <div className='content-data'>
                <h5>Japan is home to two of the world’s most cherished natural wonders: the majestic Mount Fuji (Fuji San) and the breathtaking Cherry Blossom Festival (Sakura Festival). Each spring, Japan comes alive with vibrant cherry blossoms, painting the countryside in a sea of pink as nature signals the arrival of the season.</h5>
                <img src={FUJI} alt="FUJI" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Experience the Magic of Sakura Season</h4>
              <div className='content-data'>
                <img src={blloom} alt="blloom" />
                <h5>Every year from March to April, the Sakura Festival welcomes spring as cherry blossoms blanket Japan. The festival, known locally as Sakura, symbolizes renewal and beauty, and the traditional celebration, called Hanami (which means "flower viewing"), offers visitors a chance to immerse themselves in the stunning pink blooms.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <div className='content-data1'>
                <h5>At Sagami Travels, we invite you to experience this once-in-a-lifetime spectacle. Our carefully curated tours allow Sri Lankans to enjoy the Sakura Festival while gaining a deeper appreciation of Japan’s rich culture and values.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Annual Tours Tailored for You</h4>
              <div className='content-data'>
                <img src={lamb} alt="lamb" />
                <h5>We offer three seasonal tours—Spring, Summer, and Autumn—so you can explore Japan at its most beautiful times of the year. Whether it’s witnessing the Sakura blossoms in full bloom, experiencing Japan’s vibrant summer festivals, or admiring the stunning autumn colors, Sagami Travels ensures every tour is unforgettable.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
            <h4>Travel with Confidence</h4>
              <div className='content-data'>
                <h5>Our team is fully equipped and available 24/7 to assist you with every aspect of your journey. From luxurious transportation and accommodations in star-class hotels to English-speaking guides, we make sure you’re in safe hands. Plus, our innovative Bento Box meal options and Cash Back program for meals offer excellent value.</h5>
                <img src={festival} alt="festival" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              <div className='date-container' onClick={handleNavigateToKeniya} style={{ cursor: 'pointer' }}>
                <img src={kenya} alt='kenya' className='most-img' />
                <p className='time'>KENYA SAFARI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToGeorgia} style={{ cursor: 'pointer' }}>
                <img src={georgia} alt='georgia' className='most-img' />
                <p className='time'>GEORGIA - TBILISH & DUBAI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToSydeny} style={{ cursor: 'pointer' }}>
                <img src={sydeny} alt='sydeny' className='most-img' />
                <p className='time'>SYDENY & TASMANIAN & MALBOURE</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

