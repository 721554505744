import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Global from '../Component/Global';
import '../Styles/ServicePage.css';
import Navbar from '../Component/Navbar';
import alexandria from '../Images/alexandria.png';
import kenya from '../Images/kenya.jpg';
import georgia from '../Images/georgia.jpg';
import sydeny from '../Images/sydeny.jpg';
import china from '../Images/china.jpg';
import moscow from '../Images/moscow.jpg';
import Jakarta from '../Images/Jakarta.jpg';
import Baku from '../Images/Baku.jpg';
import dubai1 from '../Images/dubai1.webp';
import Ranthambhore from '../Images/Ranthambhore.jpg';
import Tirupati from '../Images/Tirupati.jpg';
import Danang from '../Images/Danang.jpg';
import Varanasi from '../Images/Varanasi.jpg';
import Shimla from '../Images/Shimla.jpg';
import bgvideo from '../Videos/SAKURA-FLOWERS.mp4';
import Footer from '../Component/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';



export const OutBound = () => {
  

 
  const handleClick = () => {
    navigate('/japan-details');
  };

  const [showAll, setShowAll] = useState(false); 
  const servicesData = [
    {
      image: kenya,
      title: "KENYA SAFARI",
      description: "Nairobi | Nakuru Lake | Naivasha Lake | Maasai Mara",
      path: "/kenya-details"
    },
    {
      image: georgia,
      title: "GEORGIA - TBILISH & DUBAI",
      description: "Tbilisi | Batumi | Bakuriani | Uplistsikhe | Gori",
      path: "/georiga-details"
    },
    {
      image: sydeny,
      title: "SYDNEY & TASMANIA & MELBOURNE",
      description: "Sydney | Tasmania | Melbourne",
      path: "/sydeny-details"
    },
    {
      image: china,
      title: "CHINA TOUR 7N/8DAYS",
      description: "Beijing | Xian | Shanghai | Yuyuan Garden",
      path: "/china-details"
    },
    {
      image: moscow,
      title: "TRANSSIBERIAN ADVENTURE TOUR",
      description: "Moscow | Ulan-Ude | Irkutsk | Listvyanka | Yekaterinburg",
      path: "/transsiberian-details"
    },
    {
      image: alexandria,
      title: "EGYPT TOUR 08N/09D",
      description: "Doha | Alexandria | Cairo",
      path: "/egypt-details"
    },
    {
      image: Jakarta,
      title: "JAKARTA - YOGYAKARTA - BALI",
      description: "Jakarta | Miniatur Park | Prambanan | Uluwatu",
      path: "/jakarta-details"
    },
    {
      image: Baku,
      title: "BAKU & GABALA",
      description: "Baku | Gabala | Mud Volcanoes & Gobustan",
      path: "/baku-gabala-details"
    },
    {
      image: dubai1,
      title: "DUBAI TOUR",
      description: "Marina Cruise | Burj Khalifia | Miracle Garden",
      path: "/dubai-details"
    },
    {
      image: Ranthambhore,
      title: "RANTHAMBHORE TOUR",
      description: "Delhi | Agra | Ranthambhore | Jaipur",
      path: "/ranthambhore-details"
    },
    {
      image: Tirupati,
      title: "THIRUPATHI & CHENNAI SHOPPING",
      description: "Chennai | Thrirupathi Tharisanam |Thirumalai",
      path: "/Thirupathi-details"
    },
    {
      image: Danang,
      title: "VIETNAM TOUR",
      description: "Hanoi | Halong | Danang | Hoi An | Saigon",
      path: "/vietnam-details"
    },
    {
      image: Varanasi,
      title: "DAMBADIVA PILGRIMAGE",
      description: "Agra | Sankassia | Varanasi | Bodhgaya | Kushinagar",
      path: "/dambadiva-details"
    },
    {
      image: Shimla,
      title: "SHIMLA - MANALI - CHANDIGRAH",
      description: "Delhi | Shimla | Manali | Chandigrah | Rotang",
      path: "/shimla-details"
    },
  ];

  
  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  // Limit the number of services shown initially to 8
  const displayedServices = showAll ? servicesData : servicesData.slice(0, 8);
  const navigate = useNavigate();

  const handleBookNow = (path) => {
    navigate(path);
  };

  const handleMoreClick = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth', 
    });
  };

  

  return (
    <>
    
      <Navbar />
     
      <div className="service-banner">
        <video autoPlay muted loop id="background-video">
          <source src={bgvideo} type="video/mp4" />
        </video>
        <div className="overlay-content">
          <h1>Our Signature Tour</h1>
          <h2>JAPAN</h2>
          <h3>Sakura Festival</h3>
          <button onClick={handleClick}>View More Details</button>
        </div>
        <div className="scroll-more">
        <button onClick={handleMoreClick} className="more-btn">
          More Out Bound Tours
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        </div>
      </div>



        <div className="Services">
        <div className="card">
          {displayedServices.map((service, index) => (
            <div
              className="service-card"
              key={index}
              onClick={() => handleBookNow(service.path)}
              style={{ cursor: "pointer" }}
            >
              <div className="service-data">
                <div>
                  <img src={service.image} alt={service.title} />
                </div>
                <div>
                  <h3>{service.title}</h3>
                  <hr />
                  <p>{service.description}</p>
                  <hr />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Toggle button */}
        <div className='toggle'>
          <h4 onClick={toggleShowMore} style={{ cursor: 'pointer' }}>
            {showAll ? 'View Less' : 'View More'}
          </h4>
        </div>
      </div>
      <Footer/>
    </>
  );
};
