import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Navbar from '../Component/Navbar';
import { FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; // Icons from react-icons
import '../Styles/Contact.css';
import Footer from '../Component/Footer';


export const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_0sha2ml',  
        'template_wq8p8oh',  
        e.target,
        'zQ3elD1CAaFE6tpCk'      
      )
      .then(
        (result) => {
          console.log('Message Sent:', result.text);
          alert('Message sent successfully!');
          setFormData({ name: '', email: '', message: '' });
        },
        (error) => {
          console.error('Error sending message:', error);
          alert('Failed to send message, please try again.');
        }
      );
  };

  return (
    <>    
      <Navbar/>
      <div className='contact-banner'>
        <h1>Contact Us</h1>
      </div>

      <div className='contact'>
        <div className='contact-text'>
          <h3>Have a question, need assistance?</h3>
          <h3>or want to share your travel experiences?</h3>
          <p>We're here to help! Feel free to reach out to our dedicated team. Whether you're planning your next adventure or seeking information about our services, we're happy to assist you. Contact us today and let's start exploring together.</p>
          <div className="contact-details">
            <div className="contact-item">
              <FaPhone className="icon"/>
              <div>
                <p>(+94) 11 2769991</p>
                <p>(+94) 11 2769992</p>
              </div>
            </div>
            <div className="contact-item">
              <FaMapMarkerAlt className="icon"/>
              <p>No. 150, 1st floor, Dutugemunu Street, Kohuwala. (Opp. Odel).</p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="icon"/>
              <p>info@sagamitravels.com</p>
            </div>
          </div>
        </div>

        <div className='contact-form'>
          <h3>Send Us a Message</h3>
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className='form-group'>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className='form-group'>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" placeholder="Your message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit" className='submit-btn'>Submit</button>
          </form>
        </div>
      </div>

      <div className="map-container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44814.909197201385!2d79.86558552879615!3d6.876730200109255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25a7d53519fef%3A0x3355c1ff51cf0aec!2sSagami%20Travels%20%26%20Tours!5e0!3m2!1sen!2slk!4v1727674104284!5m2!1sen!2slk" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
      </div>
      <Footer/>
    </>
  )
}
