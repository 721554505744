import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import chennai from '../../Images/chennai-shop.jpg';
import conatctcall from '../../Images/contact-call.png';
import Danang from '../../Images/Danang.jpg';
import Tirupati from '../../Images/Tirupati.jpg';
import Varanasi from '../../Images/Varanasi.jpg';
import Shimla from '../../Images/Shimla.jpg';

export const ThrirupathiDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToShimla = () => {
    navigate('/shimla-details'); 
  };
  const handleNavigateToVietnam = () => {
    navigate('/vietnam-details'); 
  };
  const handleNavigateToDambadiva = () => {
    navigate('/dambadiva-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Tirupati} alt="Tirupati" />
        <div className='details'>
            <h1>THIRUPATHI & CHENNAI SHOPPING</h1>
            <h2>Chennai | Thrirupathi Tharisanam |Thirumalai</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our THIRUPATHI & CHENNAI SHOPPING</h3>

            <div className='first-day'>
              <h4>Day 01: Colombo/Chennai/Thirumalai Breakfast - Lunch - Dinner</h4>
              <div className='content-data1'>
                <h5>Arrive at Chennai airport, meet the representative, and transfer to Thrumalai hotel. In the evening, visit Thirupathi Tharisanam and stay overnight at Raj Park Hotel.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 02: Thirumalai / Chennai </h4>
              <div className='content-data'>
                <img src={chennai} alt="chennai" />
                <h5>Breakfast Morning breakfast at hotel, transfer to Chennai full day own activates Overnight Stay at hotel, Quality Inn Sabari -Chennai.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03 : Chennai</h4>
              <div className='content-data1'>
                <h5>Breakfast Morning breakfast at hotel, full day own activates,</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04 : Chennai </h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>Breakfast Morning Breakfast after transfer to Airport as per flight timing.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              
              
              
              <div className='date-container' onClick={handleNavigateToVietnam} style={{ cursor: 'pointer' }}>
                <img src={Danang} alt='Danang' className='most-img' />
                <p className='time'>VIETNAM TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToDambadiva} style={{ cursor: 'pointer' }}>
                <img src={Varanasi} alt='Varanasi' className='most-img' />
                <p className='time'>DAMBADIVA PILGRIMAGE</p>
              </div>
              <div className='date-container' onClick={handleNavigateToShimla} style={{ cursor: 'pointer' }}>
                <img src={Shimla} alt='Shimla' className='most-img' />
                <p className='time'>SHIMLA - MANALI - CHANDIGRAH</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

