import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Kataragama from '../../Images/Kataragama.jpg';
import sembuwatta from '../../Images/sembuwatta.jpg';
import Sembuwatta2 from '../../Images/Sembuwatta2.jpg';
import arugam from '../../Images/arugam-bay.jpg';
import kataragama2 from '../../Images/kataragama2.jpg';
import birdspark from '../../Images/birdspark.jpg';
import conatctcall from '../../Images/contact-call.png';

export const SembuwataDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToKataragama = () => {
    navigate('/kataragama-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Sembuwatta2} alt="sembuwatta" />
        <div className='details'>
            <h1>Sembuwatta in Matale Highlands</h1>
            <h2>Sembuwatta Lake | Hunnas Falls</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our Sembuwatta Trip</h3>

            <div className='first-day'>
              <h4>1st Day</h4>
              <div className='content-data'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Departure from Katubedda at 6.00am via Colombo for breakfast en-route(Cash Bar on Your own)</h5></li>
                    <li><h5 style={{margin:'0px'}}>Late lunch at Huns Falls Hotel(1.30pm - 2.00pm)</h5></li>
                    <li><h5 style={{margin:'0px'}}>Evening: 01 hour Native Trail to the falls.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner/overnight</h5></li>
                </ul>
                <img src={sembuwatta} alt="sembuwatta" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>2nd Day</h4>
              <div className='content-data'>
                <img src={kataragama2} alt="kataragama2" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>After Breakfast, check-oit and proceed to Sembuwatta(40 minutes): Poorly constructed road. Need to hire a tuk-ruk</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch in Kandy - Indian Summer (Garlic/ Butter Naan, Chana Masala, Tandori Chicken, Onion Sambol/ Raita, Cardamom Chai Tea)</h5></li>
                    <li><h5 style={{margin:'0px'}}>Proceed to Colombo (ETA : 7.00pm)</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <span className='time'>0778 133 133</span>
          </div>
        </div> */}
        <div className='most-popular'>
            <h1>Popular Local Tours</h1>
            <hr />
            <div className='popular'>
              <div className='date-container' onClick={handleNavigateToKataragama} style={{ cursor: 'pointer' }}>
                <img src={Kataragama} alt='Kataragama' className='most-img' />
                <p className='time'>East Coast & Kataragama</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

