import React from 'react';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import moscow from '../../Images/moscow.jpg';
import Sigiriya from '../../Images/Sigiriya.jpg';
import srilanka from '../../Images/srilanla-2.jpg';
import ruwanweliseya from '../../Images/ruwanweliseya.jpg';
import CapitalSriLanka from '../../Images/CapitalSriLanka.jpg';
import conatctcall from '../../Images/contact-call.png';
import NineArchBridge from '../../Images/NineArchBridge.jpg';
import FlyingRavana from '../../Images/FlyingRavana.jpg';

export const NewandAusDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  


  return (
    <>
     <Navbar/>
     <div className='details-section'>


     <div className='mainbar'>
        <img src={srilanka} alt="srilanka" />
        <div className='details'>
            <h1>Australia & New Zealand In Bound Tour</h1>
            <h2>Ruwanweliseya | 2nd Capital of Sri Lanka |  Sigiriya | Nine Arch Bridge</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our In Bound Tour</h3>

            <div className='first-day'>
              <h4>Day 01</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrival from Australia/New Zealand.</h5></li>
                    <li><h5 style={{margin:'0px'}}> Check in : Hotel Movenpick, Hilton or similar</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 02</h4>
              <div className='content-data'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Travel to Anuradhapura,the first Kingdom of Sri Lanka, built in 4th century BC</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in : Heritage - Anuradhapura</h5></li>
                </ul>
                <img src={ruwanweliseya} alt="ruwanweliseya" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 03</h4>
              <div className='content-data'>
                <img src={CapitalSriLanka} alt="CapitalSriLanka" />
                <ul>
                    <li><h5 style={{margin:'0px'}}> Travel to Polonnaruwa, the second Kingdom of Sri Lanka and glimpse the island's early civilization roots.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in : Aliya Resort & Spa.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 04</h4>
              <div className='content-data'>
              <ul>
                    <li><h5 style={{margin:'0px'}}> Morning visit to Sigiriya, an ancient rock fortress built by King Kashyapa in the late 5th century.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Continue to Kandy and visit the Temple of the Tooth of Lord Buddha.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check In : Suisse Hotel, Kandy.</h5></li>
                </ul> 
                <img src={Sigiriya} alt="Sigiriya" /> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 05</h4>
              <div className='content-data1'>
              <ul>
                    <li><h5 style={{margin:'0px'}}>Travel to Tea country and stay at Nuwara Eliya .</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in : The Golden Ridge Hotel, Nuwara Eliya.</h5></li>
                </ul>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 06</h4>
              <div className='content-data'>
              <ul>
                    <li><h5 style={{margin:'0px'}}> From Nanu Oya railway station, take ‘scenic train’ to Demodara station to see the world famous “Demodara Loop” , 360°  track changing directions. Visit the nine arch bridge. Stay at Ella or Haputale/Beragala.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check In : Akway Beragala by Secret Leisure / Melheim Resort.</h5></li>
                </ul>
                <img src={NineArchBridge} alt="NineArchBridge" /> 
                                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 07</h4>
              <div className='content-data'>
                <img src={FlyingRavana} alt="FlyingRavana" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>Optional Zipline “Flying Ravana” at Ella.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Travel down to Hilton Weerwila Resort.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Optional visit to Yala park by Jeeps to watch Leopards & Sloth bear (Not found in Maasai Mara - Kenya)</h5></li>

                </ul>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 08</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Travel via the Southern Highway to Bentota.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check In : Club Bentota.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 09</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>After breakfast, leave for Airport for departure.</h5>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            
        </div>


        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
        <div className='time-date'>
          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <hr />
        </div>
        <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div>
        
      </div>
     </div>
    </>
  )
}

