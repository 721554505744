import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import edfucourt from '../../Images/edfucourt.jpg';
import cairo from '../../Images/cairo.jpg';
import conatctcall from '../../Images/contact-call.png';
import alexandria from '../../Images/alexandria.png';
import dubai1 from '../../Images/dubai1.webp';
import Jakarta from '../../Images/Jakarta.jpg';
import Baku from '../../Images/Baku.jpg';

export const EgyptDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToBaku = () => {
    navigate('/baku-gabala-details'); 
  };
  const handleNavigateToDubai = () => {
    navigate('/dubai-details'); 
  };
  const handleNavigateToJakarta = () => {
    navigate('/jakarta-details'); 
  };

  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={alexandria} alt="alexandria" />
        <div className='details'>
            <h1>EGYPT TOUR 08N/09D</h1>
            <h2>Doha | Alexandria | Cairo</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our EGYPT Tour</h3>

            <div className='first-day'>
              <h4>Day 01</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrive to Borg El Arab airport, meet & assist by Travco Rep.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer by our deluxe coach to hotel in Alexandria city for check-in.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch on route to hotel in Alexandria at local restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at sea food Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight Alexandria hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 02</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>10:00 Tour to visit Catacombs, Royal Jewelry museum and Bibliotheca Alexandrina.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Italian Lunch at Minouche Restaurant (Pizza, Pasta, fries, pickles, salads & Ice cream).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Viewing Pompeys' Pillar and Fortress of Qaitbay from outside.</h5></li>
                    <li><h5 style={{margin:'0px'}}>After sightseeing, return transfer to Cairo (225KM), stop at Perfumery and Papyrus fair.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Upon arrive to Cairo, Egyptian typical Dinner at local or Indian Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight Cairo hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>


            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 03</h4>
              <div className='content-data'>
                <img src={cairo} alt="cairo" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>07.00 a.m. breakfast at hotel, ahead to start Cairo highlights visits.</h5></li>
                    <li><h5 style={{margin:'0px'}}>08:30 a.m. move ahead to visit the Pyramids of Giza and Sphinx.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visit Perfumery and Papyrus shop.</h5></li>
                    <li><h5 style={{margin:'0px'}}>01.00 p.m. Indian lunch at Red Elephant Indian Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>After lunch, proceed to visit the Egyptian Museum.</h5></li>
                    <li><h5 style={{margin:'0px'}}>At evening, dinner at local or Indian restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight Cairo hotel on BB.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
            
            <div className='first-day'>
              <h4>Day 04</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>10:00 Tour to visit Catacombs, Royal Jewelry museum and Bibliotheca Alexandrina.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Italian Lunch at Minouche Restaurant (Pizza, Pasta, fries, pickles, salads & Ice cream).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Viewing Pompeys' Pillar and Fortress of Qaitbay from outside.</h5></li>
                    <li><h5 style={{margin:'0px'}}>After sightseeing, return transfer to Cairo (225KM), stop at Perfumery and Papyrus fair.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Upon arrive to Cairo, Egyptian typical Dinner at local or Indian Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight Cairo hotel.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 05</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast on board of train; 08:00 to 08:30 a.m. arrive to Aswan.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Arrival at Aswan station, greeted by Travco representative.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Ahead directly to visit Philae Temple and High Dam, after visit, stop at Cotton shop. </h5></li>
                    <li><h5 style={{margin:'0px'}}>After visits of Aswan, check in the Nile cruise.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch and Dinner on board.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight on board - FB.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 06</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Early at the morning, 04:30AM, with breakfast box from the Nile cruise.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Drive to Abu Simbel city; visit Temple of Abu Simbel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>After visit, transfer back to Aswan; proceed to the Nile cruise for check-in.</h5></li>
                    <li><h5 style={{margin:'0px'}}>01.00 p.m. Start sailing to Kom Ombo, lunch during sailing.</h5></li>
                    <li><h5 style={{margin:'0px'}}>07.00 p.m. visit Kom Ombo Temple and Crocodile Museum.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner on board of the Nile cruise.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Sail to Edfu.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight on board – FB</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 07</h4>
              <div className='content-data'>
                <img src={edfucourt} alt="edfucourt" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>06:00 a.m. Visit Edfu Temple by horse carriage.</h5></li>
                    <li><h5 style={{margin:'0px'}}>08:00 a.m. Breakfast on board during sailing to Luxor via Esna Lock.</h5></li>
                    <li><h5 style={{margin:'0px'}}>06.00 p.m. arrive to Luxor.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visit Luxor temple in case early arrival of cruise to Luxor.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch and dinner on board of the Nile cruise.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight on board - FB</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 08</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>07.00 a.m. breakfast on board then check out.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Optional tour Hot Air Balloon.</h5></li>
                    <li><h5 style={{margin:'0px'}}>08:00 a.m. visit Valley of Kings, Queen Hatshepsut Temple and Colossi of Memnon.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Indian Lunch at Taste of India restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Continue visit Karnak Temple and Luxor Temple.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to Luxor hotel for check in.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at hotel's restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight on board – FB</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 09</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Optional tour: Hot Air Balloon over Luxor.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Breakfast at Luxor hotel and check out at noon time.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch at local restaurant in Luxor.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to Luxor airport to fly to Cairo and connection to Colombo.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

             

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
              
              
              <div className='date-container' onClick={handleNavigateToJakarta} style={{ cursor: 'pointer' }}>
                <img src={Jakarta} alt='Jakarta' className='most-img' />
                <p className='time'>JAKARTA - YOGYAKARTA - BALI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToBaku} style={{ cursor: 'pointer' }}>
                <img src={Baku} alt='moscow' className='most-img' />
                <p className='time'>BAKU & GABALA</p>
              </div>
              <div className='date-container' onClick={handleNavigateToDubai} style={{ cursor: 'pointer' }}>
                <img src={dubai1} alt='dubai1' className='most-img' />
                <p className='time'>DUBAI TOUR</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

