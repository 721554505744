import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import kenya from '../../Images/kenya.jpg';
import departure from '../../Images/flight-departure.jpg';
import conatctcall from '../../Images/contact-call.png';
import Nairobi from '../../Images/Nairobi-hotel.jpg';
import Nakuru from '../../Images/Nakuru-park.jpg';
import Naivasha from '../../Images/Naivasha.png';
import Masai from '../../Images/Masai-Mara.jpg';
import georgia from '../../Images/georgia.jpg';
import sydeny from '../../Images/sydeny.jpg';
import china from '../../Images/china.jpg';
import Safari from '../../Images/Kenya-Safari.jpg';


export const KenyaDetailsPage = () => {

  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToGeorgia = () => {
    navigate('/georiga-details'); 
  };
  const handleNavigateToSydeny = () => {
    navigate('/sydeny-details'); 
  };
  const handleNavigateToChina = () => {
    navigate('/china-details'); 
  };

  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={kenya} alt="kenya" />
        <div className='details'>
            <h1>Kenya Safari 6N/7D</h1>
            <h2>Nairobi | Nakuru Lake | Naivasha Lake | Maasai Mara</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our Kenya Safari</h3>

            <div className='first-day'>
              <h4>Day 01 : Arrival</h4>
              <div className='content-data'>
                <div>
                <h5>Arrival at the airport depending on your scheduled flight. then transfer to your Nairobi hotel for your overnight stay.</h5>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Main destination : Nairobi</h5></li>
                    <li><h5 style={{margin:'0px'}}>Accommodation : After 40 Hotel, Nairobi</h5></li>
                    <li><h5 style={{margin:'0px'}}>Meals & drinks : Bed & Breakfast included Drinking Water</h5></li>
                </ul>
                </div>
                <img src={Nairobi} alt="Nairobi" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 02 : Nairobi - Drive to lake Nakuru National park</h4>
              <div className='content-data'>
                <img src={Nakuru} alt="Nakuru" />
                <div>
                <h5>Depart in the morning to Lake Nakuru National Park via Nyahururu Thomson Falls. Enjoy an afternoon game drive, spotting flamingos, over 400 bird species, Rothschild's giraffe, rhinos, lions, and other wildlife.</h5>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Main destination : Lake Nakuru National Park</h5></li>
                    <li><h5 style={{margin:'0px'}}>Accommodation : Buraha Zenoni Resort, Nakuru</h5></li>
                    <li><h5 style={{margin:'0px'}}>Meals & drinks : All Meals included Drinking Water</h5></li>
                </ul>
                </div>              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03 : Lake Nakuru - Drive to lake Naivasha</h4>
              <div className='content-data'>
              <div>
                <h5>After breakfast, head to Lake Naivasha for a boat ride and nature walk at Crescent Island, spotting wildlife like zebras, giraffes, and wildebeest. Stay overnight at Sweet Lake Resort, Naivasha.</h5>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Main destination : Lake Naivasha</h5></li>
                    <li><h5 style={{margin:'0px'}}>Accommodation : Sweet Lake Resort, Naivasha</h5></li>
                    <li><h5 style={{margin:'0px'}}>Meals & drinks : All Meals included Drinking Water</h5></li>
                </ul>
                </div> 
                <img src={Naivasha} alt="Naivasha" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04 : Lake Naivasha - Masai Mara Game Reserve</h4>
              <div className='content-data'>
                <img src={Masai} alt="Masai" />
                <div>
                <h5>After breakfast, drive via the Great Rift Valley to Masai Mara, arriving for lunch. Enjoy an evening game drive, spotting diverse wildlife including lions, rhinos, and the famous wildebeest migration. Overnight at your camp or lodge.</h5>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Main destination : Masai Mara National Reserve</h5></li>
                    <li><h5 style={{margin:'0px'}}>Accommodation : Masai Mara Olerian Safari Camp</h5></li>
                    <li><h5 style={{margin:'0px'}}>Meals & drinks : All Meals included Drinking Water</h5></li>
                </ul>
                </div>              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>7 Days/ 06 Nights Kenya Safari Includes:</h4>
              <div className='content-data'>            
                <ul>
                    <li><h5 style={{margin:'0px'}}>Extensive Game drives</h5></li>
                    <li><h5 style={{margin:'0px'}}>Pre-tour itinerary document</h5></li>
                    <li><h5 style={{margin:'0px'}}>All applicable Game Park entrance fees</h5></li>
                    <li><h5 style={{margin:'0px'}}>Guaranteed window seating with open top</h5></li>
                    <li><h5 style={{margin:'0px'}}>Safari vehicles carry cold drinks and mineral water</h5></li>
                    <li><h5 style={{margin:'0px'}}>Guaranteed departures for one or more guests booking this safari</h5></li>
                    <li><h5 style={{margin:'0px'}}>Accommodations in Nairobi, Lake Nakuru, Naivasha and Maasai mara</h5></li>
                    <li><h5 style={{margin:'0px'}}>Multi-lingual Professional Safari Guide/ Driver</h5></li>
                    <li><h5 style={{margin:'0px'}}>Round trip transport from Nairobi to Lake Nakuru National Park, Lake Naivasha, Maasai Mara and back to Nairobi</h5></li>
                </ul> 
                <img src={Safari} alt="Safari" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 07 : Transfer Departure</h4>
              <div className='content-data'>
                <img src={departure} alt="departure" />
                <h5>After breakfast check out from your hotel, transfer to catch your scheduled International flight back home with lifetime memories of an epic African safari.</h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
        <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            
            
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
            <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            <div className='date-container' onClick={handleNavigateToGeorgia} style={{ cursor: 'pointer' }}>
              <img src={georgia} alt='georgia' className='most-img' />
              <p className='time'>GEORGIA - TBILISI & DUBAI</p>
            </div>
              <div className='date-container' onClick={handleNavigateToSydeny} style={{ cursor: 'pointer' }}>
                <img src={sydeny} alt='sydeny' className='most-img' />
                <p className='time'>SYDENY & TASMANIAN & MALBOURE</p>
              </div>
              <div className='date-container' onClick={handleNavigateToChina} style={{ cursor: 'pointer' }}>
                <img src={china} alt='china' className='most-img' />
                <p className='time'>CHINA TOUR</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

