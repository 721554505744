import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import BODHGAYA from '../../Images/BODHGAYA.jpg';
import KUSHINAGAR from '../../Images/KUSHINAGAR.webp';
import lucknow from '../../Images/lucknow.jpg';
import conatctcall from '../../Images/contact-call.png';
import Varanasi from '../../Images/Varanasi.jpg';
import Shimla from '../../Images/Shimla.jpg';
import kenya from '../../Images/kenya.jpg';
import georgia from '../../Images/georgia.jpg';

export const DambadivaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToShimla = () => {
    navigate('/shimla-details'); 
  };
  const handleNavigateToKeniya = () => {
    navigate('/kenya-details'); 
  };
  const handleNavigateToGeorgia = () => {
    navigate('/georiga-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Varanasi} alt="Varanasi" />
        <div className='details'>
            <h1>DAMBADIVA PILGRIMAGE</h1>
            <h2>Agra | Sankassia | Varanasi | Bodhgaya | Kushinagar</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our DAMBADIVA PILGRIMAGE Tour</h3>

            <div className='first-day'>
              <h4>DAY 01: ARRIVE DELHI-AGRA </h4>
              <div className='content-data1'>
                <h5>Arriving in New Delhi, will travel for approximately 3 hours over a distance of 221.6 km. After lunch, will depart for Agra, where will stay overnight and enjoy dinner.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 02: AGRA-SANKASSIA </h4>
              <div className='content-data1'>
                <h5>After breakfast, depart for Sankissa, visiting the Taj Mahal and Yamuna River along the way. Arrive in Lucknow for lunch, then continue to Sankissa for dinner and an overnight stay in Agra.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 03: LUCKNOW-VARANASI </h4>
              <div className='content-data1'>
                <h5>After breakfast, depart from Lucknow to Varanasi, a 5-hour journey, and enjoy lunch upon arrival. In the afternoon, visit Sarnath, where Buddha gave his first sermon, followed by dinner and exploration of the city.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

          
            <div className='first-day'>
              <h4 style={{textAlign:'right'}}> DAY 04: VARANASI-BODHGAYA  </h4>
              <div className='content-data'>
                <img src={BODHGAYA} alt="BODHGAYA" />
                <h5>After breakfast, depart from Varanasi to Bodhgaya for a 6-hour journey, followed by a boat safari on the Ganges River and lunch. In the afternoon, visit the Progbodhi Cave before checking in to your accommodation for dinner.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 05: BODHGAYA  </h4>
              <div className='content-data1'>
                <h5>After breakfast, perform ablutions at the Mahabodhi Temple and enjoy views of the Nerangana River. Participate in a padayatra before dinner in Bodhgaya.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 06: BODHGAY-RAJGIR  </h4>
              <div className='content-data1'>
                <h5>After breakfast, visit the Rajgir Peace Pagoda and Nalanda University. After lunch, travel to Vaishali for dinner and an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 07: VAISHALI TO KUSHINAGAR  </h4>
              <div className='content-data'>
                <h5>After breakfast, visit the Vaishali Stupa and Ashoka Pillar, then travel to Kushi Town for lunch. Arrive in Kushi Town for dinner and an overnight stay.</h5>
                <img src={KUSHINAGAR} alt="KUSHINAGAR" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 08: KUSHINAGAR TO LUMBINI  </h4>
              <div className='content-data1'>
                <h5>After breakfast in Kushi Town, visit Maha Parivana Temple, Ramabhar Stupa, and the Japanese Temple, followed by lunch. In the evening, enjoy dinner and an overnight stay in Lumbini.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 09: LUMBINI-KAPILAVASTU </h4>
              <div className='content-data1'>
                <h5>After breakfast, visit the Lumbini Sacred Garden and Mahamayadevi Temple, then drive to Kapilavastu for lunch. Continue to Shravasti for an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 10: SRAVASTI TO LUCKNOW  </h4>
              <div className='content-data1'>
                <h5>After breakfast in Shravasti, travel to Lucknow for lunch at Jetavanaram and Ananda Bodhiya, followed by dinner and an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>DAY 11: LUCKNOW TO DELHI  </h4>
              <div className='content-data'>
                <h5>After breakfast, depart for Delhi, visit the National Museum, enjoy lunch, shop, and stay overnight.</h5>
                <img src={lucknow} alt="lucknow" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>DAY 12: DELHI-DEPARTURE   </h4>
              <div className='content-data1'>
                <h5>After breakfast, enjoy shopping before departing for Sri Lanka from Delhi Airport, concluding with a prayer.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              
              <div className='date-container' onClick={handleNavigateToShimla} style={{ cursor: 'pointer' }}>
                <img src={Shimla} alt='Shimla' className='most-img' />
                <p className='time'>SHIMLA - MANALI - CHANDIGRAH</p>
              </div>
              <div className='date-container' onClick={handleNavigateToKeniya} style={{ cursor: 'pointer' }}>
                <img src={kenya} alt='kenya' className='most-img' />
                <p className='time'>KENYA SAFARI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToGeorgia} style={{ cursor: 'pointer' }}>
                <img src={georgia} alt='georgia' className='most-img' />
                <p className='time'>GEORGIA - TBILISH & DUBAI</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

