import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Chail from '../../Images/Chail.webp';
import manali from '../../Images/manali.jpg';
import conatctcall from '../../Images/contact-call.png';
import Shimla from '../../Images/Shimla.jpg';
import kenya from '../../Images/kenya.jpg';
import georgia from '../../Images/georgia.jpg';
import sydeny from '../../Images/sydeny.jpg';

export const ShimlaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToSydeny = () => {
    navigate('/sydeny-details'); 
  };
  const handleNavigateToKeniya = () => {
    navigate('/kenya-details'); 
  };
  const handleNavigateToGeorgia = () => {
    navigate('/georiga-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Shimla} alt="Shimla" />
        <div className='details'>
            <h1>SHIMLA - MANALI - CHANDIGRAH</h1>
            <h2>Delhi | Shimla | Manali | Chandigrah | Rotang</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our SHIMLA - MANALI - CHANDIGRAH Tour</h3>

            <div className='first-day'>
              <h4>Day 1: Arrive in Delhi</h4>
              <div className='content-data1'>
                <h5>After arriving at Delhi airport, check in to the hotel and freshen up. Then, embark on a city tour to visit landmarks such as India Gate, the Presidential Palace, and the Red Fort, before returning for an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 2: Delhi - Shimla </h4>
              <div className='content-data1'>
                <h5>After breakfast, check out and drive to Shimla, where you'll check in to the hotel and then enjoy an evening walk along Mall Road for shopping and dining.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 3: Shimla</h4>
              <div className='content-data'>
                <h5>After breakfast, drive to Chail to explore the Chail Palace, then head to Kufri for activities like horse rides and tobogganing. In the evening, visit Jakhu Temple before returning to the hotel in Shimla for the night.</h5>
                <img src={Chail} alt="Chail" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 4: Shimla - Manali  </h4>
              <div className='content-data1'>
                <h5>After a long drive, arrive in Manali by the evening, check into the hotel, and enjoy leisure time at Mall Road before overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 5: Manali - Solang Valley / Rohtang</h4>
              <div className='content-data1'>
                <h5>After breakfast, visit Solang Valley for local activities and enjoy the stunning views of Rohtang Pass. Return to the hotel, and if time permits, explore Hidimba Temple and Vashisth Temple before a leisurely evening at Mall Road.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 6: Manali- Chandigarh</h4>
              <div className='content-data'>
                <img src={manali} alt="manali" />
                <h5>After breakfast, check out by 9:00 AM and drive to Chandigarh, making photo stops along the way, before relaxing overnight in the city.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 7: Chandigarh to Delhi </h4>
              <div className='content-data1'>
                <h5>After breakfast and hotel check-out, visit the Rock Garden and Sukhna Lake before transferring to Delhi for overnight stay at the hotel.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 8: Delhi -Departure </h4>
              <div className='content-data1'>
                <h5>Enjoy a relaxing day for last-minute shopping before checking out and transferring to Delhi airport for your departure.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              <div className='date-container' onClick={handleNavigateToKeniya} style={{ cursor: 'pointer' }}>
                <img src={kenya} alt='kenya' className='most-img' />
                <p className='time'>KENYA SAFARI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToGeorgia} style={{ cursor: 'pointer' }}>
                <img src={georgia} alt='georgia' className='most-img' />
                <p className='time'>GEORGIA - TBILISH & DUBAI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToSydeny} style={{ cursor: 'pointer' }}>
                <img src={sydeny} alt='sydeny' className='most-img' />
                <p className='time'>SYDENY & TASMANIAN & MALBOURE</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

