import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Mahal from '../../Images/Mahal.jpg';
import Ranthambhore2 from '../../Images/Ranthambhore2.jpg';
import conatctcall from '../../Images/contact-call.png';
import Ranthambhore from '../../Images/Ranthambhore.jpg';
import Danang from '../../Images/Danang.jpg';
import Tirupati from '../../Images/Tirupati.jpg';
import Varanasi from '../../Images/Varanasi.jpg';

export const RanthambhoreDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToTirupathi = () => {
    navigate('/Thirupathi-details'); 
  };
  const handleNavigateToVietnam = () => {
    navigate('/vietnam-details'); 
  };
  const handleNavigateToDambadiva = () => {
    navigate('/dambadiva-details'); 
  };



  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Ranthambhore} alt="Ranthambhore" />
        <div className='details'>
            <h1>RANTHAMBHORE TOUR</h1>
            <h2>Delhi | Agra | Ranthambhore | Jaipur</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our RANTHAMBHORE Tour</h3>

            <div className='first-day'>
              <h4>Day 01: Arrive in Delhi</h4>
              <div className='content-data1'>
                <h5>Upon arrival, transfer to the hotel and check in. Enjoy a city tour of Delhi with stops at India Gate, Presidential Palace, Lotus Temple, Raj Ghat, Gandhi Museum, and views of Jama Masjid and Red Fort. Return to the hotel for relaxation and overnight stay in Delhi.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 02: Delhi to Agra (Driving hours around 04-05 hours)</h4>
              <div className='content-data'>
                <img src={Mahal} alt="Mahal" />
                <h5>After breakfast, transfer to Agra and check in to the hotel. Visit Agra Fort and the Taj Mahal before sunset, then stay overnight at the hotel.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03: Agra to Ranthambhore. (Driving hours around 05 hours)</h4>
              <div className='content-data1'>
                <h5>In the early morning, visit Mehtab Bagh for a view of the Taj Mahal. After check-out, drive to Ranthambhore, stopping at Fatehpur Sikri en route. Upon arrival, check in to the hotel and relax for the rest of the day. Overnight at Ranthambhore.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04: Ranthambhore</h4>
              <div className='content-data'>
                <img src={Ranthambhore2} alt="Ranthambhore2" />
                <h5>Start the day with an early morning safari in Ranthambhore National Park, home to tigers and diverse wildlife. After lunch, embark on a jungle walk and visit the Ranthambhore Fort and Trinetra Ganesha Temple, possibly spotting wildlife along the way. Overnight at Ranthambhore.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 05: Ranthambhore to Jaipur (Driving time: 3.5 hours approx.)</h4>
              <div className='content-data1'>
                <h5>Begin the day with a morning safari in Ranthambhore, then proceed to Jaipur after breakfast. In the evening, visit Galta Ji (Monkey Temple) and Birla Temple. Overnight in Jaipur.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 06: Jaipur</h4>
              <div className='content-data1'>
                <h5>After breakfast, enjoy a guided tour of Jaipur, visiting Amber Fort, Jaigarh Fort, Jal Mahal, Hawa Mahal, City Palace, and Jantar Mantar. Explore the architectural beauty and rich history of the Pink City. Evening at leisure, overnight in Jaipur.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07: Jaipur to Delhi (Driving hours around 04-05 hours)</h4>
              <div className='content-data1'>
                <h5>Today after breakfast, check out from the hotel and get transfer to Jaipur or Delhi Airport for your flight back home.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Send us a Whatsapp Message for Booking Your Tour</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call For Booking</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              
              
              <div className='date-container' onClick={handleNavigateToTirupathi} style={{ cursor: 'pointer' }}>
                <img src={Tirupati} alt='Tirupati' className='most-img' />
                <p className='time'>THIRUPATHI & CHENNAI SHOPPING</p>
              </div>
              <div className='date-container' onClick={handleNavigateToVietnam} style={{ cursor: 'pointer' }}>
                <img src={Danang} alt='Danang' className='most-img' />
                <p className='time'>VIETNAM TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToDambadiva} style={{ cursor: 'pointer' }}>
                <img src={Varanasi} alt='Varanasi' className='most-img' />
                <p className='time'>DAMBADIVA PILGRIMAGE</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

